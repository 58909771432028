@font-face {
	font-family: "map-glyphs";
	src: url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/saunders/fonts/map-glyphs.woff2?1c28adbe53f11f78cc40b57ffad8a0d2") format("woff2"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/saunders/fonts/map-glyphs.woff?1c28adbe53f11f78cc40b57ffad8a0d2") format("woff"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/saunders/fonts/map-glyphs.ttf?1c28adbe53f11f78cc40b57ffad8a0d2") format("truetype"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/saunders/fonts/map-glyphs.svg?1c28adbe53f11f78cc40b57ffad8a0d2#map-glyphs") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* line 18, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs, .record-marker::before, .record-marker::after,
.record-marker .record-marker__label::before,
.record-marker .record-marker__label-text::before, .localguide-marker::before, .localguide-marker::after,
.localguide-marker .record-marker__label::before,
.localguide-marker .record-marker__label-text::before, .school-marker::before, .school-marker::after,
.school-marker .record-marker__label::before,
.school-marker .record-marker__label-text::before, .poi-marker::before, .poi-marker::after,
.poi-marker .record-marker__label::before,
.poi-marker .record-marker__label-text::before, .radius-controls::before, .radius-controls::after,
.radius-controls .record-marker__label::before,
.radius-controls .record-marker__label-text::before, .cluster-marker::before, .cluster-marker::after,
.cluster-marker .record-marker__label::before,
.cluster-marker .record-marker__label-text::before, .region-marker::before, .region-marker::after,
.region-marker .record-marker__label::before,
.region-marker .record-marker__label-text::before, .selectable-record-marker::before, .selectable-record-marker::after,
.selectable-record-marker .record-marker__label::before,
.selectable-record-marker .record-marker__label-text::before, .record-marker.marker-type-zip:not(.marker-has-text) .record-marker__label {
	font-family: "map-glyphs";
	display: inline-block;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* line 38, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-bank:before {
	content: "\f101";
}

/* line 41, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-building-full:before {
	content: "\f102";
}

/* line 44, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-building:before {
	content: "\f103";
}

/* line 47, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-community:before {
	content: "\f104";
}

/* line 50, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-flag:before {
	content: "\f105";
}

/* line 53, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-full-screen-exit:before {
	content: "\f106";
}

/* line 56, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-full-screen:before {
	content: "\f107";
}

/* line 59, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-gas:before {
	content: "\f108";
}

/* line 62, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-groceries:before {
	content: "\f109";
}

/* line 65, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-house-1:before {
	content: "\f10a";
}

/* line 68, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-house-2-full:before {
	content: "\f10b";
}

/* line 71, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-house-2:before {
	content: "\f10c";
}

/* line 74, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-house-3:before {
	content: "\f10d";
}

/* line 77, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-multiple-listings-2:before {
	content: "\f10e";
}

/* line 80, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-multiple-listings:before {
	content: "\f10f";
}

/* line 83, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-open-house:before {
	content: "\f110";
}

/* line 86, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-checkbox-square-checked:before {
	content: "\f111";
}

/* line 89, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-checkbox-square:before {
	content: "\f112";
}

/* line 92, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-checkbox:before {
	content: "\f113";
}

/* line 95, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-circle:before {
	content: "\f114";
}

/* line 98, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-cluster:before {
	content: "\f115";
}

/* line 101, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-hexagon:before {
	content: "\f116";
}

/* line 104, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-plus:before {
	content: "\f117";
}

/* line 107, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-simple:before {
	content: "\f118";
}

/* line 110, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-square:before {
	content: "\f119";
}

/* line 113, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin-target:before {
	content: "\f11a";
}

/* line 116, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-pin:before {
	content: "\f11b";
}

/* line 119, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-radius-center:before {
	content: "\f11c";
}

/* line 122, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-radius-resize:before {
	content: "\f11d";
}

/* line 125, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-restaurant:before {
	content: "\f11e";
}

/* line 128, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-school:before {
	content: "\f11f";
}

/* line 131, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-unknown:before {
	content: "\f120";
}

/* line 134, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-zip-target-2:before {
	content: "\f121";
}

/* line 137, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-zip-target:before {
	content: "\f122";
}

/* line 140, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.icon-map-glyphs-zip:before {
	content: "\f123";
}

/* line 19, scss/20-tools/_map-marker-mixins.scss */
.record-marker, .localguide-marker, .school-marker, .poi-marker, .radius-controls, .cluster-marker {
	font-size: 14px;
	font-size: 1rem;
	transform-origin: center bottom;
	position: relative;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	transition: all .2s ease-out;
	line-height: 0;
}

/* line 59, scss/20-tools/_map-marker-mixins.scss */
.record-marker::before, .record-marker::after, .localguide-marker::before, .localguide-marker::after, .school-marker::before, .school-marker::after, .poi-marker::before, .poi-marker::after, .radius-controls::before, .radius-controls::after, .cluster-marker::before, .cluster-marker::after {
	font-size: 2.7em;
}

/* line 64, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label::before,
.record-marker .record-marker__label-text::before, .localguide-marker .record-marker__label::before,
.localguide-marker .record-marker__label-text::before, .school-marker .record-marker__label::before,
.school-marker .record-marker__label-text::before, .poi-marker .record-marker__label::before,
.poi-marker .record-marker__label-text::before, .radius-controls .record-marker__label::before,
.radius-controls .record-marker__label-text::before, .cluster-marker .record-marker__label::before,
.cluster-marker .record-marker__label-text::before {
	font-size: 2em;
}

/* line 69, scss/20-tools/_map-marker-mixins.scss */
.record-marker::after, .record-marker::before,
.record-marker .record-marker__label,
.record-marker .record-marker__label-text,
.record-marker .record-marker__label::before,
.record-marker .record-marker__label-text::before,
.record-marker .cluster-marker__data, .localguide-marker::after, .localguide-marker::before,
.localguide-marker .record-marker__label,
.localguide-marker .record-marker__label-text,
.localguide-marker .record-marker__label::before,
.localguide-marker .record-marker__label-text::before,
.localguide-marker .cluster-marker__data, .school-marker::after, .school-marker::before,
.school-marker .record-marker__label,
.school-marker .record-marker__label-text,
.school-marker .record-marker__label::before,
.school-marker .record-marker__label-text::before,
.school-marker .cluster-marker__data, .poi-marker::after, .poi-marker::before,
.poi-marker .record-marker__label,
.poi-marker .record-marker__label-text,
.poi-marker .record-marker__label::before,
.poi-marker .record-marker__label-text::before,
.poi-marker .cluster-marker__data, .radius-controls::after, .radius-controls::before,
.radius-controls .record-marker__label,
.radius-controls .record-marker__label-text,
.radius-controls .record-marker__label::before,
.radius-controls .record-marker__label-text::before,
.radius-controls .cluster-marker__data, .cluster-marker::after, .cluster-marker::before,
.cluster-marker .record-marker__label,
.cluster-marker .record-marker__label-text,
.cluster-marker .record-marker__label::before,
.cluster-marker .record-marker__label-text::before,
.cluster-marker .cluster-marker__data {
	display: inline-block;
}

/* line 79, scss/20-tools/_map-marker-mixins.scss */
.record-marker::after,
.record-marker .record-marker__label,
.record-marker .record-marker__label::before,
.record-marker .cluster-marker__data, .localguide-marker::after,
.localguide-marker .record-marker__label,
.localguide-marker .record-marker__label::before,
.localguide-marker .cluster-marker__data, .school-marker::after,
.school-marker .record-marker__label,
.school-marker .record-marker__label::before,
.school-marker .cluster-marker__data, .poi-marker::after,
.poi-marker .record-marker__label,
.poi-marker .record-marker__label::before,
.poi-marker .cluster-marker__data, .radius-controls::after,
.radius-controls .record-marker__label,
.radius-controls .record-marker__label::before,
.radius-controls .cluster-marker__data, .cluster-marker::after,
.cluster-marker .record-marker__label,
.cluster-marker .record-marker__label::before,
.cluster-marker .cluster-marker__data {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

/* line 94, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label,
.record-marker .record-marker__label::before,
.record-marker .cluster-marker__data, .localguide-marker .record-marker__label,
.localguide-marker .record-marker__label::before,
.localguide-marker .cluster-marker__data, .school-marker .record-marker__label,
.school-marker .record-marker__label::before,
.school-marker .cluster-marker__data, .poi-marker .record-marker__label,
.poi-marker .record-marker__label::before,
.poi-marker .cluster-marker__data, .radius-controls .record-marker__label,
.radius-controls .record-marker__label::before,
.radius-controls .cluster-marker__data, .cluster-marker .record-marker__label,
.cluster-marker .record-marker__label::before,
.cluster-marker .cluster-marker__data {
	transform: translateY(-50%);
	top: 50%;
}

/* line 102, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label::before, .localguide-marker .record-marker__label::before, .school-marker .record-marker__label::before, .poi-marker .record-marker__label::before, .radius-controls .record-marker__label::before, .cluster-marker .record-marker__label::before {
	top: 0;
}

/* line 106, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label,
.record-marker .record-marker__label-text,
.record-marker .cluster-marker__data, .localguide-marker .record-marker__label,
.localguide-marker .record-marker__label-text,
.localguide-marker .cluster-marker__data, .school-marker .record-marker__label,
.school-marker .record-marker__label-text,
.school-marker .cluster-marker__data, .poi-marker .record-marker__label,
.poi-marker .record-marker__label-text,
.poi-marker .cluster-marker__data, .radius-controls .record-marker__label,
.radius-controls .record-marker__label-text,
.radius-controls .cluster-marker__data, .cluster-marker .record-marker__label,
.cluster-marker .record-marker__label-text,
.cluster-marker .cluster-marker__data {
	font-family: var(--font-family-text);
	font-display: swap;
}

/* line 113, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label, .localguide-marker .record-marker__label, .school-marker .record-marker__label, .poi-marker .record-marker__label, .radius-controls .record-marker__label, .cluster-marker .record-marker__label {
	padding-bottom: 0.47143em;
}

/* line 116, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label-text, .localguide-marker .record-marker__label-text, .school-marker .record-marker__label-text, .poi-marker .record-marker__label-text, .radius-controls .record-marker__label-text, .cluster-marker .record-marker__label-text {
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
	white-space: nowrap;
}

/* line 120, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label-text::before, .localguide-marker .record-marker__label-text::before, .school-marker .record-marker__label-text::before, .poi-marker .record-marker__label-text::before, .radius-controls .record-marker__label-text::before, .cluster-marker .record-marker__label-text::before {
	vertical-align: top;
}

/* line 126, scss/20-tools/_map-marker-mixins.scss */
.record-marker .cluster-marker__data, .localguide-marker .cluster-marker__data, .school-marker .cluster-marker__data, .poi-marker .cluster-marker__data, .radius-controls .cluster-marker__data, .cluster-marker .cluster-marker__data {
	font-size: 1rem;
}

/* line 19, scss/20-tools/_map-marker-mixins.scss */
.region-marker, .selectable-record-marker {
	font-size: 14px;
	font-size: 1rem;
	transform-origin: center bottom;
	position: relative;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	transition: all .2s ease-out;
	line-height: 0;
}

/* line 59, scss/20-tools/_map-marker-mixins.scss */
.region-marker::before, .region-marker::after, .selectable-record-marker::before, .selectable-record-marker::after {
	font-size: 1.5em;
}

/* line 64, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label::before,
.region-marker .record-marker__label-text::before, .selectable-record-marker .record-marker__label::before,
.selectable-record-marker .record-marker__label-text::before {
	font-size: 2em;
}

/* line 69, scss/20-tools/_map-marker-mixins.scss */
.region-marker::after, .region-marker::before,
.region-marker .record-marker__label,
.region-marker .record-marker__label-text,
.region-marker .record-marker__label::before,
.region-marker .record-marker__label-text::before,
.region-marker .cluster-marker__data, .selectable-record-marker::after, .selectable-record-marker::before,
.selectable-record-marker .record-marker__label,
.selectable-record-marker .record-marker__label-text,
.selectable-record-marker .record-marker__label::before,
.selectable-record-marker .record-marker__label-text::before,
.selectable-record-marker .cluster-marker__data {
	display: inline-block;
}

/* line 79, scss/20-tools/_map-marker-mixins.scss */
.region-marker::after,
.region-marker .record-marker__label,
.region-marker .record-marker__label::before,
.region-marker .cluster-marker__data, .selectable-record-marker::after,
.selectable-record-marker .record-marker__label,
.selectable-record-marker .record-marker__label::before,
.selectable-record-marker .cluster-marker__data {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

/* line 94, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label,
.region-marker .record-marker__label::before,
.region-marker .cluster-marker__data, .selectable-record-marker .record-marker__label,
.selectable-record-marker .record-marker__label::before,
.selectable-record-marker .cluster-marker__data {
	transform: translateY(-50%);
	top: 50%;
}

/* line 102, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label::before, .selectable-record-marker .record-marker__label::before {
	top: 0;
}

/* line 106, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label,
.region-marker .record-marker__label-text,
.region-marker .cluster-marker__data, .selectable-record-marker .record-marker__label,
.selectable-record-marker .record-marker__label-text,
.selectable-record-marker .cluster-marker__data {
	font-family: var(--font-family-text);
	font-display: swap;
}

/* line 113, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label, .selectable-record-marker .record-marker__label {
	padding-bottom: 0.47143em;
}

/* line 116, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label-text, .selectable-record-marker .record-marker__label-text {
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
	white-space: nowrap;
}

/* line 120, scss/20-tools/_map-marker-mixins.scss */
.region-marker .record-marker__label-text::before, .selectable-record-marker .record-marker__label-text::before {
	vertical-align: top;
}

/* line 126, scss/20-tools/_map-marker-mixins.scss */
.region-marker .cluster-marker__data, .selectable-record-marker .cluster-marker__data {
	font-size: 1rem;
}

/* line 918, scss/80-themes/Saunders/60-components/map-markers.scss */
.localguide-marker:before,
.school-marker:before,
.poi-marker:before,
.radius-controls:before,
.cluster-marker:not(.cluster-marker--location):before,
.region-marker:before,
.selectable-record-marker:before {
	text-shadow: -1px -1px 0 #fff, -1px 0px 0 #fff, -1px 1px 0 #fff, 0px -1px 0 #fff, 0px 0px 0 #fff, 0px 1px 0 #fff, 1px -1px 0 #fff, 1px 0px 0 #fff, 1px 1px 0 #fff;
}

/* line 929, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker__image-tooltip {
	width: 125px;
	margin: 0 auto;
}

/* line 934, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker__text-tooltip {
	text-align: center;
}

/* line 940, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker__text-tooltip + .record-marker__image-tooltip {
	margin-top: 5px;
}

/* line 945, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker {
	min-height: 25px;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.record-marker:before {
	content: "\f11b";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.record-marker .record-marker__label:before,
.record-marker .record-marker__label-text:before {
	content: "\f120";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label-text,
.record-marker .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label::before,
.record-marker .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.record-marker--hover::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.record-marker--hover .record-marker__label-text,
.record-marker.record-marker--hover .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.record-marker--hover::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.record-marker--hover .record-marker__label::before,
.record-marker.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.record-marker--hover .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-highlight::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-highlight .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-highlight::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-highlight .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal .record-marker__label-text,
.record-marker.marker-status-normal .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal .record-marker__label::before,
.record-marker.marker-status-normal .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.record-marker--hover::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-normal.record-marker--hover .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.record-marker--hover::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-normal.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.record-marker--hover .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.marker-status-highlight::before {
	color: rgb(var(--color-global-primary));
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-normal.marker-status-highlight .cluster-marker__data {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.marker-status-highlight::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-normal.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-normal.marker-status-highlight .record-marker__label-text::after {
	border-left-color: rgb(var(--color-global-primary));
	border-bottom-color: rgb(var(--color-global-primary));
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new::before {
	color: #748c30;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new .record-marker__label-text,
.record-marker.marker-status-new .cluster-marker__data {
	background-color: #748c30;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new .record-marker__label::before,
.record-marker.marker-status-new .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new .record-marker__label-text::after {
	border-left-color: #748c30;
	border-bottom-color: #748c30;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.record-marker--hover::before {
	color: #748c30;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-new.record-marker--hover .cluster-marker__data {
	background-color: #748c30;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-new.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.record-marker--hover .record-marker__label-text::after {
	border-left-color: #748c30;
	border-bottom-color: #748c30;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.marker-status-highlight::before {
	color: #748c30;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-new.marker-status-highlight .cluster-marker__data {
	background-color: #748c30;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-new.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-new.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #748c30;
	border-bottom-color: #748c30;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced::before {
	color: #b14232;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced .record-marker__label-text,
.record-marker.marker-status-pricereduced .cluster-marker__data {
	background-color: #b14232;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced .record-marker__label::before,
.record-marker.marker-status-pricereduced .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced .record-marker__label-text::after {
	border-left-color: #b14232;
	border-bottom-color: #b14232;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.record-marker--hover::before {
	color: #b14232;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-pricereduced.record-marker--hover .cluster-marker__data {
	background-color: #b14232;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-pricereduced.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.record-marker--hover .record-marker__label-text::after {
	border-left-color: #b14232;
	border-bottom-color: #b14232;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.marker-status-highlight::before {
	color: #b14232;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-pricereduced.marker-status-highlight .cluster-marker__data {
	background-color: #b14232;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-pricereduced.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-pricereduced.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #b14232;
	border-bottom-color: #b14232;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse::before {
	color: #324b72;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse .record-marker__label-text,
.record-marker.marker-status-openhouse .cluster-marker__data {
	background-color: #324b72;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse .record-marker__label::before,
.record-marker.marker-status-openhouse .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse .record-marker__label-text::after {
	border-left-color: #324b72;
	border-bottom-color: #324b72;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.record-marker--hover::before {
	color: #324b72;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-openhouse.record-marker--hover .cluster-marker__data {
	background-color: #324b72;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-openhouse.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.record-marker--hover .record-marker__label-text::after {
	border-left-color: #324b72;
	border-bottom-color: #324b72;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.marker-status-highlight::before {
	color: #324b72;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-openhouse.marker-status-highlight .cluster-marker__data {
	background-color: #324b72;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-openhouse.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-openhouse.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #324b72;
	border-bottom-color: #324b72;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse::before {
	color: #547dbf;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse .record-marker__label-text,
.record-marker.marker-status-virtualopenhouse .cluster-marker__data {
	background-color: #547dbf;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse .record-marker__label::before,
.record-marker.marker-status-virtualopenhouse .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse .record-marker__label-text::after {
	border-left-color: #547dbf;
	border-bottom-color: #547dbf;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.record-marker--hover::before {
	color: #547dbf;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-virtualopenhouse.record-marker--hover .cluster-marker__data {
	background-color: #547dbf;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-virtualopenhouse.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.record-marker--hover .record-marker__label-text::after {
	border-left-color: #547dbf;
	border-bottom-color: #547dbf;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.marker-status-highlight::before {
	color: #547dbf;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-virtualopenhouse.marker-status-highlight .cluster-marker__data {
	background-color: #547dbf;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-virtualopenhouse.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-virtualopenhouse.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #547dbf;
	border-bottom-color: #547dbf;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon::before {
	color: #bbb;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon .record-marker__label-text,
.record-marker.marker-status-comingsoon .cluster-marker__data {
	background-color: #bbb;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon .record-marker__label::before,
.record-marker.marker-status-comingsoon .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon .record-marker__label-text::after {
	border-left-color: #bbb;
	border-bottom-color: #bbb;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.record-marker--hover::before {
	color: #bbb;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-comingsoon.record-marker--hover .cluster-marker__data {
	background-color: #bbb;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-comingsoon.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.record-marker--hover .record-marker__label-text::after {
	border-left-color: #bbb;
	border-bottom-color: #bbb;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.marker-status-highlight::before {
	color: #bbb;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-comingsoon.marker-status-highlight .cluster-marker__data {
	background-color: #bbb;
	color: #FFF;
	border: 1px solid #FFF;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-comingsoon.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-comingsoon.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #bbb;
	border-bottom-color: #bbb;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending::before {
	color: #ddd;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending .record-marker__label-text,
.record-marker.marker-status-contractpending .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending .record-marker__label::before,
.record-marker.marker-status-contractpending .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.record-marker--hover::before {
	color: #ddd;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-contractpending.record-marker--hover .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.record-marker--hover::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-contractpending.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.record-marker--hover .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.marker-status-highlight::before {
	color: #ddd;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-contractpending.marker-status-highlight .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.marker-status-highlight::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-contractpending.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-contractpending.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract::before {
	color: #ddd;
	text-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract .record-marker__label-text,
.record-marker.marker-status-undercontract .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract .record-marker__label::before,
.record-marker.marker-status-undercontract .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.record-marker--hover::before {
	color: #ddd;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-undercontract.record-marker--hover .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.record-marker--hover::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-undercontract.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.record-marker--hover .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.marker-status-highlight::before {
	color: #ddd;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-undercontract.marker-status-highlight .cluster-marker__data {
	background-color: #ddd;
	color: rgb(var(--color-global-primary));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.marker-status-highlight::after {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-undercontract.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-undercontract.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #ddd;
	border-bottom-color: #ddd;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent::before {
	color: #666;
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent .record-marker__label-text,
.record-marker.marker-status-recent .cluster-marker__data {
	background-color: #666;
	color: #FFF;
	border: 1px solid #666;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent .record-marker__label::before,
.record-marker.marker-status-recent .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent .record-marker__label-text::after {
	border-left-color: #666;
	border-bottom-color: #666;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.record-marker--hover::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-recent.record-marker--hover .cluster-marker__data {
	background-color: #666;
	color: #FFF;
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-recent.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.record-marker--hover .record-marker__label-text::after {
	border-left-color: #666;
	border-bottom-color: #666;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.marker-status-highlight::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-recent.marker-status-highlight .cluster-marker__data {
	background-color: #666;
	color: #FFF;
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-recent.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-recent.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #666;
	border-bottom-color: #666;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting::before {
	color: #333;
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting .record-marker__label-text,
.record-marker.marker-status-soldlisting .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid #333;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting .record-marker__label::before,
.record-marker.marker-status-soldlisting .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.record-marker--hover::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-soldlisting.record-marker--hover .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-soldlisting.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.record-marker--hover .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.marker-status-highlight::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-soldlisting.marker-status-highlight .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-soldlisting.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-soldlisting.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented::before {
	color: #333;
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented .record-marker__label-text,
.record-marker.marker-status-rented .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid #333;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented .record-marker__label::before,
.record-marker.marker-status-rented .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.record-marker--hover::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.record-marker--hover .record-marker__label-text,
.record-marker.marker-status-rented.record-marker--hover .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.record-marker--hover::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.record-marker--hover .record-marker__label::before,
.record-marker.marker-status-rented.record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.record-marker--hover .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.marker-status-highlight::before {
	color: #FFF;
	text-shadow: 0px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.marker-status-highlight .record-marker__label-text,
.record-marker.marker-status-rented.marker-status-highlight .cluster-marker__data {
	background-color: #333;
	color: #FFF;
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.marker-status-highlight::after {
	background-color: transparent;
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.marker-status-highlight .record-marker__label::before,
.record-marker.marker-status-rented.marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: #FFF;
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-status-rented.marker-status-highlight .record-marker__label-text::after {
	border-left-color: #333;
	border-bottom-color: #333;
	box-shadow: -1px 1px 0px #fff, -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract)::before {
	color: #fdf3c2;
	text-shadow: 0px 1px 0px transparent, 1px -1px 0px transparent, -1px -1px 0px transparent, 0px 3px 4px rgba(0, 0, 0, 0.5);
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label-text,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .cluster-marker__data {
	background-color: #fdf3c2;
	color: rgb(var(--color-global-positive));
	border: 1px solid transparent;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract)::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label::before,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label-text::after {
	border-left-color: #fdf3c2;
	border-bottom-color: #fdf3c2;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover::before {
	color: #fdf3c2;
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover .record-marker__label-text,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover .cluster-marker__data {
	background-color: #fdf3c2;
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover .record-marker__label::before,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).record-marker--hover .record-marker__label-text::after {
	border-left-color: #fdf3c2;
	border-bottom-color: #fdf3c2;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight::before {
	color: #fdf3c2;
	text-shadow: 0px 1px 0px rgb(var(--color-global-positive)), 1px -1px 0px rgb(var(--color-global-positive)), -1px -1px 0px rgb(var(--color-global-positive)), 0px 3px 4px rgb(var(--color-global-positive));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight .record-marker__label-text,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight .cluster-marker__data {
	background-color: #fdf3c2;
	color: rgb(var(--color-global-positive));
	border: 1px solid rgb(var(--color-global-positive));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight::after {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight .record-marker__label::before,
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight .record-marker__label-text::before {
	background-color: transparent;
	color: rgb(var(--color-global-positive));
}

/* line 266, scss/20-tools/_map-marker-mixins.scss */
.record-marker.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-comingsoon):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract).marker-status-highlight .record-marker__label-text::after {
	border-left-color: #fdf3c2;
	border-bottom-color: #fdf3c2;
	box-shadow: -1px 1px 0px rgb(var(--color-global-positive)), -3px 3px 2px rgba(0, 0, 0, 0.5);
}

/* line 997, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker .record-marker__label-text {
	position: relative;
	border-radius: 3px;
	line-height: 1;
	padding-top: 1px;
}

/* line 1006, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker .record-marker__label-text:after {
	transform-origin: center;
	transform: translate(-50%, 0%) rotate(-45deg);
	left: 50%;
	content: '';
	position: absolute;
	bottom: -4px;
	width: 0;
	height: 0;
	box-sizing: border-box;
	border-top: 5px solid transparent;
	border-right: 5px solid transparent;
	border-left: 5px solid;
	border-bottom: 5px solid;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .record-marker .record-marker__label-text:after {
	left: auto;
	right: 50%;
}

/* line 1024, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.record-marker-highlight--hover {
	transform: scale(1.3);
	transform-origin: bottom center;
}

/* line 1029, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker .record-marker__text {
	padding: 3px 5px;
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	font-size: 0.85rem;
	line-height: 1;
}

/* line 1056, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-type-multilisting:not(.marker-has-number) .record-marker__label-text:before, .record-marker.marker-type-multisoldlisting:not(.marker-has-number) .record-marker__label-text:before, .record-marker.marker-type-zip:not(.marker-has-number) .record-marker__label-text:before, .record-marker.marker-type-multizip:not(.marker-has-number) .record-marker__label-text:before, .record-marker.marker-type-multicommunity:not(.marker-has-number) .record-marker__label-text:before, .record-marker.marker-type-multioffice:not(.marker-has-number) .record-marker__label-text:before {
	font-size: 1.143rem;
	padding: 4px 5px;
}

/* line 1061, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-type-multilisting:not(.marker-has-number) .record-marker__text, .record-marker.marker-type-multisoldlisting:not(.marker-has-number) .record-marker__text, .record-marker.marker-type-zip:not(.marker-has-number) .record-marker__text, .record-marker.marker-type-multizip:not(.marker-has-number) .record-marker__text, .record-marker.marker-type-multicommunity:not(.marker-has-number) .record-marker__text, .record-marker.marker-type-multioffice:not(.marker-has-number) .record-marker__text {
	padding-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .record-marker.marker-type-multilisting:not(.marker-has-number) .record-marker__text, .is-rtl .record-marker.marker-type-multisoldlisting:not(.marker-has-number) .record-marker__text, .is-rtl .record-marker.marker-type-zip:not(.marker-has-number) .record-marker__text, .is-rtl .record-marker.marker-type-multizip:not(.marker-has-number) .record-marker__text, .is-rtl .record-marker.marker-type-multicommunity:not(.marker-has-number) .record-marker__text, .is-rtl .record-marker.marker-type-multioffice:not(.marker-has-number) .record-marker__text {
	padding-left: initial;
	padding-right: 0;
}

/* line 1067, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-type-multilisting:not(.marker-has-number) .record-marker__label-text:before {
	padding: 1px 5px;
}

/* line 1072, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-has-number {
	min-width: 22px;
}

/* line 1076, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-item-count {
	font-size: 1rem;
	min-height: initial;
}

/* line 1080, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-item-count .record-marker__label {
	font-size: 14px;
	font-size: 1rem;
	color: #FFF;
}

/* line 1089, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-item-count.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label, .record-marker.marker-item-count.marker-status-normal .record-marker__label, .record-marker.marker-item-count.marker-status-recent .record-marker__label {
	color: rgb(var(--color-global-positive));
}

/* line 1092, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-item-count.marker-is-featured:not(.marker-status-soldlisting):not(.marker-status-rented):not(.marker-status-pricereduced):not(.marker-status-new):not(.marker-status-virtualopenhouse):not(.marker-status-openhouse):not(.marker-status-contractpending):not(.marker-status-undercontract) .record-marker__label:hover, .record-marker.marker-item-count.marker-status-normal .record-marker__label:hover, .record-marker.marker-item-count.marker-status-recent .record-marker__label:hover {
	color: rgb(var(--color-global-positive));
}

/* line 1099, scss/80-themes/Saunders/60-components/map-markers.scss */
.record-marker.marker-has-number:before,
.record-marker.marker-has-number .record-marker__label-text:before,
.record-marker.marker-item-count .record-marker__label:before, .record-marker.marker-has-text:before,
.record-marker.marker-type-listing .record-marker__label-text:before,
.record-marker.marker-type-community .record-marker__label-text:before, .record-marker.marker-type-flag:before,
.record-marker.marker-type-soldlisting .record-marker__label-text:before {
	content: "";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.record-marker.marker-type-zip:not(.marker-has-text) .record-marker__label:before {
	content: "\f122";
}

/* line 1143, scss/80-themes/Saunders/60-components/map-markers.scss */
.listingdetails .record-marker--static {
	cursor: default;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-listing:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-listing .record-marker__label:before,
.marker-type-listing .record-marker__label-text:before {
	content: "\f10a";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multilisting:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multilisting .record-marker__label:before,
.marker-type-multilisting .record-marker__label-text:before {
	content: "\f10e";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-community:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-community .record-marker__label:before,
.marker-type-community .record-marker__label-text:before {
	content: "\f10c";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-office:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-office .record-marker__label:before,
.marker-type-office .record-marker__label-text:before {
	content: "\f103";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-zip:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-zip .record-marker__label:before,
.marker-type-zip .record-marker__label-text:before {
	content: "\f121";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multizip:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multizip .record-marker__label:before,
.marker-type-multizip .record-marker__label-text:before {
	content: "\f121";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multicommunity:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multicommunity .record-marker__label:before,
.marker-type-multicommunity .record-marker__label-text:before {
	content: "\f10b";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multioffice:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multioffice .record-marker__label:before,
.marker-type-multioffice .record-marker__label-text:before {
	content: "\f102";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-drivetime-destination:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-drivetime-destination .record-marker__label:before,
.marker-type-drivetime-destination .record-marker__label-text:before {
	content: "\f118";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-flag:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-flag .record-marker__label:before,
.marker-type-flag .record-marker__label-text:before {
	content: "\f105";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-soldlisting:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-soldlisting .record-marker__label:before,
.marker-type-soldlisting .record-marker__label-text:before {
	content: "\f10a";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multisoldlisting:before {
	content: "\f119";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multisoldlisting .record-marker__label:before,
.marker-type-multisoldlisting .record-marker__label-text:before {
	content: "\f10e";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.region-marker:before {
	content: "\f113";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.region-marker:after {
	content: "\f112";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-normal::before {
	color: #527cbd;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-normal .record-marker__label::before,
.region-marker.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-normal.region-marker--hover {
	transform: scale(1.15);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-normal.region-marker--hover::before {
	color: #7798cb;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight {
	transform: scale(1.25);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight::before {
	color: #527cbd;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight::after {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.region-marker.marker-status-highlight::after {
	content: "\f111";
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight .record-marker__label::before,
.region-marker.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.region-marker.marker-status-highlight .record-marker__label::before,
.region-marker.marker-status-highlight .record-marker__label-text::before {
	content: "\f111";
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight.region-marker--hover {
	transform: scale(1.3);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.region-marker.marker-status-highlight.region-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-normal::before {
	color: #1b283e;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-normal.region-marker--hover::before {
	color: #2a3f62;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-highlight::before {
	color: #1b283e;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-highlight.region-marker--hover::before {
	color: #2a3f62;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-normal::before {
	color: #647289;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-normal.region-marker--hover::before {
	color: #7f8ca1;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-highlight::before {
	color: #647289;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-highlight.region-marker--hover::before {
	color: #7f8ca1;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-normal::before {
	color: #314a71;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-normal.region-marker--hover::before {
	color: #406195;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-highlight::before {
	color: #314a71;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-highlight.region-marker--hover::before {
	color: #406195;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-normal::before {
	color: #527cbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-normal.region-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-highlight::before {
	color: #527cbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-highlight.region-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-normal::before {
	color: #8b9fbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-normal.region-marker--hover::before {
	color: #acbacf;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-highlight::before {
	color: #8b9fbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-highlight.region-marker--hover::before {
	color: #acbacf;
}

/* line 1175, scss/80-themes/Saunders/60-components/map-markers.scss */
.status-marker {
	margin-right: 10px;
	width: 32px;
	height: 32px;
	display: inline-block;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
	border-radius: 3px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .status-marker {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .status-marker {
		margin-right: 0;
	}
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-normal {
	background-color: rgb(var(--color-global-primary));
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-new {
	background-color: #748c30;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-pricereduced {
	background-color: #b14232;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-openhouse {
	background-color: #324b72;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-virtualopenhouse {
	background-color: #547dbf;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-comingsoon {
	background-color: #bbb;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-contractpending {
	background-color: #ddd;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-undercontract {
	background-color: #ddd;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-recent {
	background-color: #666;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-soldlisting {
	background-color: #333;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-rented {
	background-color: #333;
}

/* line 278, scss/20-tools/_map-marker-mixins.scss */
.status-marker.marker-status-featured {
	background-color: #fdf3c2;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.selectable-record-marker:before {
	content: "\f113";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.selectable-record-marker:after {
	content: "\f112";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-normal::before {
	color: #527cbd;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-normal .record-marker__label::before,
.selectable-record-marker.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-normal.selectable-record-marker--hover {
	transform: scale(1.15);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-normal.selectable-record-marker--hover::before {
	color: #7798cb;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight {
	transform: scale(1.25);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight::before {
	color: #527cbd;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight::after {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.selectable-record-marker.marker-status-highlight::after {
	content: "\f111";
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight .record-marker__label::before,
.selectable-record-marker.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.selectable-record-marker.marker-status-highlight .record-marker__label::before,
.selectable-record-marker.marker-status-highlight .record-marker__label-text::before {
	content: "\f111";
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight.selectable-record-marker--hover {
	transform: scale(1.3);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.selectable-record-marker.marker-status-highlight.selectable-record-marker--hover::before {
	color: #7798cb;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multilistings.marker-status-normal::after {
	content: "\f117";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-multilistings.marker-status-normal .record-marker__label::before,
.marker-type-multilistings.marker-status-normal .record-marker__label-text::before {
	content: "\f117";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.localguide-marker:before {
	content: "\f113";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.localguide-marker:after {
	content: "\f11a";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-normal::before {
	color: #527cbd;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-normal .record-marker__label::before,
.localguide-marker.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-normal.localguide-marker--hover {
	transform: scale(1.15);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-normal.localguide-marker--hover::before {
	color: #7798cb;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-highlight {
	transform: scale(1.25);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-highlight::before {
	color: #527cbd;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-highlight.localguide-marker--hover {
	transform: scale(1.3);
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.localguide-marker.marker-status-highlight.localguide-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-normal::before {
	color: #1b283e;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-normal.localguide-marker--hover::before {
	color: #2a3f62;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-highlight::before {
	color: #1b283e;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-country.marker-status-highlight.localguide-marker--hover::before {
	color: #2a3f62;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-normal::before {
	color: #647289;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-normal.localguide-marker--hover::before {
	color: #7f8ca1;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-highlight::before {
	color: #647289;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-state.marker-status-highlight.localguide-marker--hover::before {
	color: #7f8ca1;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-normal::before {
	color: #314a71;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-normal.localguide-marker--hover::before {
	color: #406195;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-highlight::before {
	color: #314a71;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-county.marker-status-highlight.localguide-marker--hover::before {
	color: #406195;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-normal::before {
	color: #527cbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-normal.localguide-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-highlight::before {
	color: #527cbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-city.marker-status-highlight.localguide-marker--hover::before {
	color: #7798cb;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-normal::before {
	color: #8b9fbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-normal.localguide-marker--hover::before {
	color: #acbacf;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-highlight::before {
	color: #8b9fbd;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-neighborhood.marker-status-highlight.localguide-marker--hover::before {
	color: #acbacf;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.school-marker:before {
	content: "\f11b";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.school-marker:after {
	content: "\f11f";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-public::before {
	color: #75c13a;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-public::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-public .record-marker__label::before,
.school-marker.marker-status-public .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-private::before {
	color: #71a6c8;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-private::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-private .record-marker__label::before,
.school-marker.marker-status-private .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-charter::before {
	color: #e7715d;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-charter::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-charter .record-marker__label::before,
.school-marker.marker-status-charter .record-marker__label-text::before {
	color: #FFF;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-highlight {
	transform: scale(1.25);
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.school-marker.marker-status-highlight.school-marker--hover {
	transform: scale(1.25);
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.poi-marker:before {
	content: "\f11b";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.poi-marker:after {
	content: "\f120";
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.poi-marker.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.poi-marker.marker-status-normal .record-marker__label::before,
.poi-marker.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 229, scss/20-tools/_map-marker-mixins.scss */
.poi-marker.marker-status-highlight {
	transform: scale(1.25);
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.poi-marker.marker-status-highlight::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.poi-marker.marker-status-highlight .record-marker__label::before,
.poi-marker.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-normal::before {
	color: #75c13a;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-normal .record-marker__label::before,
.marker-type-restaurants.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-highlight::before {
	color: #abdb86;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-highlight::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-restaurants.marker-status-highlight .record-marker__label::before,
.marker-type-restaurants.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-restaurants:not(.cluster-marker):after {
	content: "\f11e";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-restaurants .record-marker__label:before,
.marker-type-restaurants .record-marker__label-text:before {
	content: "\f11e";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-normal::before {
	color: #fd9e11;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-normal .record-marker__label::before,
.marker-type-banks.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-highlight::before {
	color: #fec776;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-highlight::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-banks.marker-status-highlight .record-marker__label::before,
.marker-type-banks.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-banks:not(.cluster-marker):after {
	content: "\f101";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-banks .record-marker__label:before,
.marker-type-banks .record-marker__label-text:before {
	content: "\f101";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-normal::before {
	color: #2fb9ba;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-normal .record-marker__label::before,
.marker-type-grocery.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-highlight::before {
	color: #73dbdc;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-highlight::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-grocery.marker-status-highlight .record-marker__label::before,
.marker-type-grocery.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-grocery:not(.cluster-marker):after {
	content: "\f109";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-grocery .record-marker__label:before,
.marker-type-grocery .record-marker__label-text:before {
	content: "\f109";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-normal::before {
	color: #71a6c8;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-normal .record-marker__label::before,
.marker-type-servicestations.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-highlight::before {
	color: #bbd4e4;
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-highlight::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.marker-type-servicestations.marker-status-highlight .record-marker__label::before,
.marker-type-servicestations.marker-status-highlight .record-marker__label-text::before {
	color: #FFF;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-servicestations:not(.cluster-marker):after {
	content: "\f108";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-servicestations .record-marker__label:before,
.marker-type-servicestations .record-marker__label-text:before {
	content: "\f108";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.radius-controls:before {
	content: "\f114";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.radius-controls:after {
	content: "\f120";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.radius-controls.marker-status-normal::before {
	color: rgb(var(--color-global-primary));
}

/* line 251, scss/20-tools/_map-marker-mixins.scss */
.radius-controls.marker-status-normal::after {
	color: #FFF;
}

/* line 258, scss/20-tools/_map-marker-mixins.scss */
.radius-controls.marker-status-normal .record-marker__label::before,
.radius-controls.marker-status-normal .record-marker__label-text::before {
	color: #FFF;
}

/* line 1275, scss/80-themes/Saunders/60-components/map-markers.scss */
.is-touch-device .radius-controls {
	display: none;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-center:not(.cluster-marker):after {
	content: "\f11c";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-center .record-marker__label:before,
.marker-type-center .record-marker__label-text:before {
	content: "\f11c";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-handle:not(.cluster-marker):after {
	content: "\f11d";
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.marker-type-handle .record-marker__label:before,
.marker-type-handle .record-marker__label-text:before {
	content: "\f11d";
}

/* line 1285, scss/80-themes/Saunders/60-components/map-markers.scss */
.cluster-marker {
	cursor: inherit;
}

/* line 156, resources/80-themes/Saunders/fonts/_map-glyphs.scss */
.cluster-marker:before {
	content: "\f115";
}

/* line 236, scss/20-tools/_map-marker-mixins.scss */
.cluster-marker.marker-status-normal::before {
	color: rgb(var(--color-global-primary));
}

/* line 243, scss/20-tools/_map-marker-mixins.scss */
.cluster-marker.marker-status-normal .record-marker__label-text,
.cluster-marker.marker-status-normal .cluster-marker__data {
	color: #FFF;
}

/* line 1295, scss/80-themes/Saunders/60-components/map-markers.scss */
.cluster-marker:hover {
	cursor: pointer;
	transform: scale(1.15);
}

/* line 1300, scss/80-themes/Saunders/60-components/map-markers.scss */
.cluster-marker--location {
	font-size: 0.4rem;
}

/* line 1303, scss/80-themes/Saunders/60-components/map-markers.scss */
.cluster-marker--location:hover {
	transform: none;
}

/* line 1312, scss/80-themes/Saunders/60-components/map-markers.scss */
.c-map-loader .o-loading-dot {
	padding: 0 1px;
}

/* line 1317, scss/80-themes/Saunders/60-components/map-markers.scss */
.c-map-loader.is-active ~ .paginationcontainer {
	margin-top: 40px;
}

/* line 1321, scss/80-themes/Saunders/60-components/map-markers.scss */
.c-map-loader.is-active ~ .home-values__map-message {
	display: none;
}

/* line 1332, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble--multilisting:not(.is-center) {
	display: none;
}

/* line 1336, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__wrapper {
	width: 235px;
	height: 175px;
	position: relative;
}

/* line 1341, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__wrapper .o-smartimage {
	border-radius: 6px;
}

/* line 1345, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__wrapper .o-imagenav--small {
	width: 17px;
	height: 26px;
	font-size: 1.2em;
}

/* line 1350, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__wrapper .o-imagenav--small:before {
	font-weight: 700;
}

@media only screen and (max-width: 29.999em) {
	/* line 1336, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubbles__container .lite-bubble__wrapper {
		width: 100%;
		height: 250px;
	}
	/* line 1359, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubbles__container .lite-bubble__wrapper .o-smartimage {
		border-radius: 0;
	}
}

/* line 1366, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble--multilisting .o-smartimage {
	border-radius: 6px 6px 0px 0px;
}

/* line 1370, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble--multilisting .lite-bubble__listing-title {
	border-radius: 0;
}

@media only screen and (max-width: 29.999em) {
	/* line 1375, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubbles__container .lite-bubble--multilisting .o-smartimage {
		border-radius: 0;
	}
}

/* line 1381, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__data {
	display: flex;
	position: absolute;
	width: 100%;
	text-align: center;
	z-index: 9;
	background-color: rgba(var(--color-global-negative), .5);
	border-radius: 6px 6px 0px 0px;
}

/* line 1390, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__data div {
	flex-grow: 1;
	font-size: 0.85em;
	text-transform: uppercase;
	font-weight: 700;
	padding: 7px;
}

@media only screen and (max-width: 29.999em) {
	/* line 1381, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubbles__container .lite-bubble__data {
		border-radius: 0;
	}
}

/* line 1410, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__flag {
	position: absolute;
	top: 26px;
	display: block;
	z-index: 9;
	padding: 5px;
	font-size: 0.7em;
}

/* line 1424, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__flag.flag--soldorrented {
	color: rgb(var(--color-global-negative));
}

/* line 1429, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__listing-title {
	position: absolute;
	bottom: 0;
	z-index: 9;
	display: block;
	background: rgba(var(--color-global-negative), .5);
	width: 100%;
	text-align: center;
	border-radius: 0px 0px 6px 6px;
}

/* line 1439, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubbles__container .lite-bubble__listing-title span {
	padding: 7px;
	font-size: 0.85em;
	font-weight: 700;
	display: block;
}

@media only screen and (max-width: 29.999em) {
	/* line 1429, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubbles__container .lite-bubble__listing-title {
		border-radius: 0;
	}
}

/* line 1460, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubble__pagination {
	display: flex;
	color: 0px 0px 6px 6px;
	background-color: #5b5b5b;
	border-radius: 0px 0px 6px 6px;
}

/* line 1466, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubble__pagination-next, .lite-bubble__pagination-prev, .lite-bubble__pagination-index {
	padding: 5px;
	color: rgb(var(--color-global-positive));
	line-height: 1.5;
	font-size: 0.85em;
}

/* line 1472, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubble__pagination-next:hover, .lite-bubble__pagination-prev:hover, .lite-bubble__pagination-index:hover {
	color: 0px 0px 6px 6px;
}

/* line 1477, scss/80-themes/Saunders/60-components/map-markers.scss */
.lite-bubble__pagination-index {
	flex-grow: 1;
	font-weight: 700;
	text-transform: uppercase;
}

@media only screen and (max-width: 29.999em) {
	/* line 1460, scss/80-themes/Saunders/60-components/map-markers.scss */
	.lite-bubble__pagination {
		border-radius: 0;
	}
}

/*# sourceMappingURL=../../../../true */